import { BaseNavHeader } from '../BaseNavHeader'
import { EnhanceItemsConfig, NavHeaderProps } from '../types'
import { enhanceNavItems } from '../utils'
import { AppProvider } from '../AppProvider'
import { useUserMenu } from './hooks/useUserMenu'
import { TeacherNavItemID } from './types'
import { IconExternalLinkLine } from '@instructure/ui-icons'
import { NAV_ITEM_ICON_SIZE } from '../BaseNavHeader/const'

const defaultEnhanceConfig: EnhanceItemsConfig<TeacherNavItemID> = {
  // Example of how to add icons to the nav items
  // user_settings: {
  //   renderBeforeLabel: () => <IconUserLine />,
  //   renderAfterLabel: () => <IconUserLine />,
  // },
  administration_reporting_external_link: {
    renderAfterLabel: () => <IconExternalLinkLine size={NAV_ITEM_ICON_SIZE} />,
  },
  administration_reporting_videri_launcher: {
    renderAfterLabel: () => <IconExternalLinkLine size={NAV_ITEM_ICON_SIZE} />,
  },
}

export const TeacherNavHeader = ({
  navItems,
  activeIds,
  userMenuItem: userMenuItemConfig,
  helpItem,
  enhanceConfig = defaultEnhanceConfig,
}: NavHeaderProps<TeacherNavItemID>) => {
  const userMenuItem = useUserMenu(userMenuItemConfig)

  const enhancedNavItems = enhanceNavItems(navItems, enhanceConfig)

  return (
    <BaseNavHeader
      navItems={enhancedNavItems}
      activeIds={activeIds}
      userMenuItem={userMenuItem}
      helpItem={helpItem}
    />
  )
}

export const WithAppProvider = (props: NavHeaderProps<TeacherNavItemID>) => (
  <AppProvider>
    <TeacherNavHeader {...props} />
  </AppProvider>
)
