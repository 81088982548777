import { NavHeaderProps } from '../types'
import { BaseNavHeader } from '../BaseNavHeader'
import { AppProvider } from '../AppProvider'
import { StudentNavItemID } from './types'
import { useUserMenu } from './hooks/useUserMenu'

const StudentNavHeader = ({
  navItems,
  activeIds,
  userMenuItem: userMenuItemConfig,
}: NavHeaderProps<StudentNavItemID>) => {
  const userMenuItem = useUserMenu(userMenuItemConfig)

  return (
    <BaseNavHeader
      navItems={navItems}
      activeIds={activeIds}
      userMenuItem={userMenuItem}
    />
  )
}

const WithAppProvider = (props: NavHeaderProps<StudentNavItemID>) => (
  <AppProvider>
    <StudentNavHeader {...props} />
  </AppProvider>
)

export { StudentNavHeader, WithAppProvider }
