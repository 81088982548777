import { Spinner } from '@instructure/ui'

import { Flex } from '@instructure/ui-flex'

import { DelayedRender } from '../../../../mc-ui/elements/DelayedRender'
import { View } from '@instructure/ui-view'

const DRILLDOWN_OPTION_HEIGHT = 36
const DRILLDOWN_OPTION_VERTICAL_PADDINGS = 16

export const MenuLoader = ({ itemsCount }: { itemsCount: number }) => {
  // To avoid the submenu from jumping around when the loader is rendered and then replaced with the submenu,
  // we need to calculate the height of the loader based on the number of items in the submenu
  // In current implementation the MenuLoader is rendered as a Drilldown.Option label,
  // so we need to subtract the vertical paddings
  const calculatedHeight =
    itemsCount * DRILLDOWN_OPTION_HEIGHT - DRILLDOWN_OPTION_VERTICAL_PADDINGS
  const height = `${calculatedHeight}px`
  return (
    <View as={'div'} height={height} data-qa={'user-menu_loader'}>
      <DelayedRender delay={300}>
        <Flex as={'div'} height={'100%'} width={'100%'}>
          <Spinner
            margin={'auto auto'}
            renderTitle="Loading"
            size={itemsCount > 2 ? 'medium' : 'small'}
          />
        </Flex>
      </DelayedRender>
    </View>
  )
}
