import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from './ErrorFallback'
import { StrictMode, useRef } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import type { DefaultOptions } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const queryConfig: DefaultOptions = {
  queries: {
    refetchOnWindowFocus: false,
  },
}
export const AppProvider = ({ children }) => {
    const queryClientRef = useRef(
      new QueryClient({ defaultOptions: queryConfig })
    )
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <QueryClientProvider client={queryClientRef.current}>
        <StrictMode>{children}</StrictMode>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ErrorBoundary>
  )
}
