import { TopNavBar } from '@instructure/ui'
import { useState } from 'react'
import { IconMasteryLogo } from '../../../../mc-icons/svg'

import { HelpItem, NavItem, OnNavigate } from './types'
import { renderNavItems } from './renderNavItems'
import { PaywallModal } from '../../../common/PaywallModal'
import { UserMenuItems } from './UserMenuItems'
import { TeacherNavHeaderHelpItem } from '../TeacherNavHeader/TeacherNavHeaderHelpItem'

type NavHeaderProps = {
  navItems: NavItem[]
  userMenuItem?: NavItem
  helpItem?: HelpItem
  activeIds: string[]
  noBorderBottom?: boolean
}
export const BaseNavHeader = ({
  navItems,
  activeIds: initialActiveIds,
  userMenuItem,
  helpItem,
  noBorderBottom
}: NavHeaderProps) => {
  const [activeIds, setActiveIds] = useState(initialActiveIds)

  const [showPaywall, setShowPaywall] = useState(false)

  const handleShowPaywall = () => {
    setShowPaywall(true)
  }

  const handleOnNavigate: OnNavigate = (id) => {
    const idArray = Array.isArray(id) ? id : [id]
    setActiveIds(idArray)
  }

  return (
    <>
      <PaywallModal open={showPaywall} onCancel={() => setShowPaywall(false)} />
      <TopNavBar inverseColor={true} breakpoint="10rem">
        {() => (
          <TopNavBar.Layout
            themeOverride={(_, { colors }) => ({
              desktopBackgroundInverse: colors.backgroundLight,
              desktopBrandContainerInlineMargin: 0,
              ...(noBorderBottom ? { desktopBottomBorderInverse: 'transparent' } : {})
            })}
            smallViewportConfig={{
              dropdownMenuToggleButtonLabel: 'Toggle Menu',
              dropdownMenuLabel: 'Main Menu',
            }}
            renderBrand={
              <TopNavBar.Brand
                themeOverride={(_, { spacing }) => ({
                  iconPadding: `0 ${spacing.xSmall} 0 ${spacing.medium}`,
                })}
                renderIcon={
                  <IconMasteryLogo
                    width={'36px'}
                    height={'36px'}
                    color={'success'}
                  />
                }
                screenReaderLabel="Mastery Connect"
              />
            }
            renderMenuItems={
              navItems ? (
                <TopNavBar.MenuItems
                  listLabel="Page navigation"
                  currentPageId={activeIds[0]}
                  renderHiddenItemsMenuTriggerLabel={(hiddenChildrenCount) =>
                    `${hiddenChildrenCount} More`
                  }
                >
                  {renderNavItems({
                    items: navItems,
                    activeIds: activeIds,
                    onNavigate: handleOnNavigate,
                    onShowPaywall: handleShowPaywall,
                  })}
                </TopNavBar.MenuItems>
              ) : null
            }
            renderActionItems={
              helpItem ? (
                <TopNavBar.ActionItems
                  listLabel="Actions"
                  renderHiddenItemsMenuTriggerLabel={(hiddenChildrenCount) =>
                    `${hiddenChildrenCount} more actions`
                  }
                >
                  <TeacherNavHeaderHelpItem id={'help'} helpItem={helpItem} />
                </TopNavBar.ActionItems>
              ) : undefined
            }
            renderUser={
              userMenuItem ? (
                <TopNavBar.User>
                  <UserMenuItems
                    userMenuItem={userMenuItem}
                    activeIds={activeIds}
                    onNavigate={handleOnNavigate}
                    onShowPaywall={handleShowPaywall}
                  />
                </TopNavBar.User>
              ) : undefined
            }
          />
        )}
      </TopNavBar>
    </>
  )
}
