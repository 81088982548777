import { useQuery } from '@tanstack/react-query'

import { CurrentTeacherResource } from '../../../../../mc-resources/internal/current-teacher'

const currentTeacherResource = CurrentTeacherResource.createInstance()
export const useCurrentTeacherQuery = (enabled: boolean) => {
  return useQuery(
    ['get_current_teacher'],
    () => currentTeacherResource.read(),
    {
      enabled,
    }
  )
}
