import { Flex } from '@instructure/ui-flex'
import { Button, Text } from '@instructure/ui'
import { Heading } from '@instructure/ui-heading'

export const ErrorFallback = () => {
  return (
    <Flex
      as="div"
      justifyItems={'center'}
      alignItems={'center'}
      role="alert"
      padding={'large'}
    >
      <Text color={'danger'}>
        <Heading>Oops, something went wrong :(</Heading>
      </Text>
      <Button margin={'0 0 0 large'} onClick={() => window.location.reload()}>
        Refresh
      </Button>
    </Flex>
  )
}
