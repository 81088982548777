import { useState } from 'react'
import { useCurrentTeacherQuery } from './useCurrentTeacherQuery'
import { enhanceNavItems } from '../../utils'
import { NavItem } from '../../BaseNavHeader/types'
import { MenuLoader } from '../MenuLoader'
import {
  IconCheckMarkSolid,
  IconInboxLine,
  IconSettingsLine,
  IconUserLine,
} from '@instructure/ui'
import { NAV_ITEM_ICON_SIZE } from '../../BaseNavHeader/const'
import { View } from '@instructure/ui-view'
import {
  IconExportLine,
  IconGroupLine,
  IconPinLine,
} from '@instructure/ui-icons'
import { NavItemConfig } from '../../types'
import { TeacherNavItemID } from '../types'
import { TeacherDTO } from '../../../../../mc-resources/internal/current-teacher/CurrentTeacherResource'

/**
 * Creates a submenu for the linked accounts
 */
const createAccountsSubmenu = (
  accounts: TeacherDTO[],
  currentTeacherId: number,
  withParentAccount: TeacherDTO | null
) => {
  const accountsSubmenu: NavItem[] = accounts.map((teacher) => ({
    id: `user_accounts_${teacher.id}`,
    label: teacher.school.name,

    form: {
      action: `/current_user/sessions/${teacher.id}`,
      method: 'patch',
    },

    // add a checkmark icon to the current teacher's account to make it look like Selection Group
    renderBeforeLabel: () =>
      currentTeacherId === teacher.id ? (
        <IconCheckMarkSolid />
      ) : (
        <View as={'div'} margin={'0 medium 0 0'} />
      ),
  }))

  if (withParentAccount) {
    accountsSubmenu.push({
      id: `user_accounts_parent`,
      label: 'Parent Account',
      href: '/switch_roles',
      renderBeforeLabel: () =>
        currentTeacherId === withParentAccount.parent.id ? (
          <IconCheckMarkSolid />
        ) : (
          <View as={'div'} margin={'0 medium 0 0'} />
        ),
    })
  }

  return accountsSubmenu
}

/**
 * Creates an item for the linked accounts
 */
const createAccountItem = (submenu: NavItem[]) =>
  submenu.length
    ? {
        id: 'user_accounts',
        label: 'Accounts',
        submenu,
      }
    : null

const userEnhanceConfig = {
  user_accounts: {
    renderBeforeLabel: () => <IconGroupLine size={NAV_ITEM_ICON_SIZE} />,
  },
  user_inbox: {
    renderBeforeLabel: () => <IconInboxLine size={NAV_ITEM_ICON_SIZE} />,
  },
  user_logout: {
    renderBeforeLabel: () => <IconExportLine size={NAV_ITEM_ICON_SIZE} />,
  },
  user_pins: {
    renderBeforeLabel: () => <IconPinLine size={NAV_ITEM_ICON_SIZE} />,
  },
  user_settings: {
    renderBeforeLabel: () => <IconSettingsLine size={NAV_ITEM_ICON_SIZE} />,
  },
  user_profiles: {
    renderBeforeLabel: () => <IconUserLine size={NAV_ITEM_ICON_SIZE} />,
  },
}

const useUserMenu = (
  userMenuItemConfig: NavItemConfig<TeacherNavItemID>,
  initialUserMenuOpen = false
) => {
  const [userMenuOpened, setUserMenuOpened] = useState(initialUserMenuOpen)

  // This is the query that gets the current teacher data when the user menu is opened
  const { data: currentTeacher } = useCurrentTeacherQuery(userMenuOpened)
  const handleUserMenuClick = () => {
    setUserMenuOpened(true)
  }

  const loginableTeachers = currentTeacher?.allTeachers.filter(
    (teach) => teach.loginable
  )

  const teacherWithParentAccount = currentTeacher?.allTeachers.find(
    (teach) => teach.parent.loginable
  )

  const showAccountsItem =
    loginableTeachers?.length > 1 || teacherWithParentAccount

  // If there are linked accounts, create a submenu for them
  const accountsSubmenu = showAccountsItem
    ? createAccountsSubmenu(
        loginableTeachers,
        currentTeacher?.id,
        teacherWithParentAccount
      )
    : []
  const accountsItem = createAccountItem(accountsSubmenu)

  // Add the accounts submenu to the user menu items if there are linked accounts
  const userMenuItems = [
    {
      ...userMenuItemConfig,
      submenu: [
        ...(accountsItem ? [accountsItem] : []),
        ...userMenuItemConfig.submenu,
      ],
    },
  ]

  // Adding Icons to the user menu items
  const enhancedUserItem = enhanceNavItems(userMenuItems, userEnhanceConfig)[0]

  // show the loading spinner if the user menu is opened and the current teacher data is not yet available
  const userMenuItem: NavItem = currentTeacher
    ? {
        ...enhancedUserItem,
      }
    : {
        id: userMenuItemConfig.id,
        label: userMenuItemConfig.label,
        onClick: handleUserMenuClick,
        submenu: [
          {
            id: 'user_loading',
            label: <MenuLoader itemsCount={enhancedUserItem.submenu.length} />,
          },
        ],
      }

  return userMenuItem
}

export { useUserMenu }
