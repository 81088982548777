import { EnhanceItemsConfig } from '../types'
import { NavItem } from '../BaseNavHeader/types'

export const enhanceNavItems = <T extends string>(
  navItems: NavItem[],
  config: EnhanceItemsConfig<T>
): NavItem[] => {
  return navItems.map((item) => {
    const itemConfig = { ...config[item.id] }
    return {
      ...item,
      ...itemConfig,
      ...(item.submenu && { submenu: enhanceNavItems(item.submenu, config) }),
    }
  })
}
