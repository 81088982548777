import QS from 'qs'
import { fetchResource } from './fetchResource'
import { FetchResourceConfig, ResourceRequestMetadata } from './types'
import { camelizeKeys, snakeCaseKeys } from '../mc-sdk/case-utils'

/**
 * Resources abstraction layer
 *
 * @TODO: cover with tests
 *
 */
class AbstractResource {
  // keeping params variable to allow its usage in descendant classes created with `createInstance` method
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  constructor(...params) {
    if (this.constructor === AbstractResource) {
      throw new Error(
        `Abstract classes "AbstractResource" can't be instantiated directly.`
      )
    }
  }

  //@TODO: make this configurable - for now relative paths should work in most cases
  _apiBaseUrl = ''

  /**
   * Endpoint URI (omitting host)
   * //@TODO: make this configurable - for now resources are always used with the same endpoint
   * @type {string}
   * @private
   */
  _endpointUrl = '/'

  _camelizeKeys = false
  _snakeCaseKeys = false

  _buildResourcePath = (resourceId) => `${this._endpointUrl}/${resourceId}`

  create(data, metadata?: ResourceRequestMetadata) {
    const requestConfig = {
      method: 'POST',
      body: JSON.stringify(this._snakeCaseKeys ? snakeCaseKeys(data) : data),
    }
    if (metadata?.responseType == 'json') {
      requestConfig['headers'] = { 'Accept': 'application/json' }
    }
    return this.fetchResource(this._endpointUrl, requestConfig)
  }

  read(resourceId) {
    return this.fetchResource(this._buildResourcePath(resourceId))
  }

  readWithQuery(resourceId, query = null) {
    let queryStr = ''
    if (query && typeof query === 'object') {
      queryStr = this._buildQueryString(query)
    }
    return this.fetchResource(`${this._buildResourcePath(resourceId)}${queryStr}`)
  }

  update(data, resourceId) {
    return this.fetchResource(this._buildResourcePath(resourceId), {
      method: 'PATCH',
      body: JSON.stringify(this._snakeCaseKeys ? snakeCaseKeys(data) : data),
    })
  }

  put(data, resourceId) {
    return this.fetchResource(`${this._buildResourcePath(resourceId)}`, {
      method: 'PUT',
      body: JSON.stringify(this._snakeCaseKeys ? snakeCaseKeys(data) : data),
    })
  }

  list(query = null) {
    let queryStr = ''
    if (query && typeof query === 'object') {
      queryStr = this._buildQueryString(this._snakeCaseKeys ? snakeCaseKeys(query) : query)
    }
    return this.fetchResource(`${this._endpointUrl}${queryStr}`)
  }

  delete(resourceId, data?: Record<string, unknown>) {
    return this.fetchResource(`${this._buildResourcePath(resourceId)}`, {
      method: 'DELETE',
      body: data && JSON.stringify(data),
    })
  }

  upload(formData: FormData, resourceId) {
    return this.fetchResource(
      `${this._buildResourcePath(resourceId)}`,
      {
        method: 'POST',
        body: formData,
      },
      {
        setDefaultContentType: false,
      }
    )
  }

  fetchResource = (url: string, init?: RequestInit, config?: FetchResourceConfig) =>
    fetchResource(this._buildAbsoluteUrl(url), init, config).then((data) =>
      this._camelizeKeys ? camelizeKeys(data) : data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ) as Promise<any>

  _buildAbsoluteUrl(uri) {
    return this._apiBaseUrl + uri
  }

  _buildQueryString(obj) {
    // This matches the format Rails expects, including nested objects, etc.
    return (
      '?' +
      QS.stringify(obj, { encodeValuesOnly: true, arrayFormat: 'brackets' })
    )
  }

  // // ugly hack for polymorphic "this" for static members in TS. Check https://github.com/microsoft/TypeScript/issues/5863
  static createInstance<T extends typeof AbstractResource>(
    this: T,
    ...params
  ): InstanceType<T> {
    return new this(...params) as InstanceType<T>
  }
}

export { AbstractResource }
