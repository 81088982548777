import { EnhanceItemsConfig, NavHeaderProps } from '../types'
import { BaseNavHeader } from '../BaseNavHeader'
import { AppProvider } from '../AppProvider'
import { enhanceNavItems } from '../utils'
import { IconSettingsLine, IconExportLine, IconUserLine } from '@instructure/ui'
import { NAV_ITEM_ICON_SIZE } from '../BaseNavHeader/const'
import { ParentNavItemID } from "./types"

const defaultEnhanceConfig: EnhanceItemsConfig<ParentNavItemID> = {
  user_logout: {
    renderBeforeLabel: () => <IconExportLine size={NAV_ITEM_ICON_SIZE} />,
  },
  user_settings: {
    renderBeforeLabel: () => <IconSettingsLine size={NAV_ITEM_ICON_SIZE} />,
  },
  user_switch_to_teacher: {
    renderBeforeLabel: () => <IconUserLine size={NAV_ITEM_ICON_SIZE} />,
  },
}


const ParentsNavHeader = ({
  navItems,
  activeIds,
  userMenuItem: userMenuItemConfig,
  enhanceConfig = defaultEnhanceConfig,
}: NavHeaderProps<ParentNavItemID>) => {
  const userMenuItems = [
    {
      ...userMenuItemConfig,
      submenu: [...userMenuItemConfig.submenu],
    },
  ]

  const enhancedUserItem = enhanceNavItems(userMenuItems, enhanceConfig)[0]

  return (
    <BaseNavHeader
      navItems={navItems}
      activeIds={activeIds}
      userMenuItem={enhancedUserItem}
    />
  )
}

const WithAppProvider = (props: NavHeaderProps<ParentNavItemID>) => (
  <AppProvider>
    <ParentsNavHeader {...props} />
  </AppProvider>
)

export { ParentsNavHeader, WithAppProvider }
