import { camelizeKeys } from '../../mc-sdk/case-utils'
import { createRoot } from 'react-dom/client'

/**
 * Renders a React component inside a DOM container with the given 'rootId'.
 * If a React root already exists for this container, it updates the component.
 * Otherwise, it creates a new React root.
 *
 * @param Component - React component to be rendered.
 * @param rootId - The ID of the DOM container where the component will be mounted.
 * @param throwError - Whether to throw an error if the container is not found, defaults to 'false'.
 * @param root - An existing React root to update, or 'null' to create one, defaults to 'null'.
 *
 * @returns The React root that was either passed in or created.
 *
 * @throws Will throw an error if the container is not found and 'throwError' is set to 'true'.
 */
export const renderComponent = (
  Component: (any) => JSX.Element,
  rootId,
  throwError = false,
  root = null
) => {
  const container = document.getElementById(rootId)

  if (!container) {
    if (throwError) {
      throw new Error(`Unable to find container with id ${rootId}`)
    }
    return
  }

  const props = camelizeKeys(JSON.parse(container.dataset.props || '{}'))
  container.classList.add('inst-ui-container')

  const reactRoot = root || createRoot(container)

  reactRoot.render(<Component {...props} />)
  return reactRoot
}
