import {
  Button,
  Flex,
  Heading,
  IconQuestionLine,
  Link,
  Text,
  TopNavBar,
  View,
} from '@instructure/ui'
import { selectorAttrs } from 'mc-ui/utils/selectorAttrs'
import { useState } from 'react'
import { HelpItem } from '../BaseNavHeader/types'

export const TeacherNavHeaderHelpItem = ({
  helpItem,
  id = 'help',
}: {
  helpItem: HelpItem
  // the `id` prop is explicitly set here only because the inst-ui TopNavBarActionItems expects it
  id?: string
}) => {
  const [showHelp, setShowHelp] = useState(false)

  return (
    <TopNavBar.Item
      id={id}
      key={'help'}
      variant={'icon'}
      tooltip={'Help'}
      renderIcon={<IconQuestionLine />}
      showSubmenuChevron={false}
      customPopoverConfig={{
        withArrow: false,
        isShowingContent: showHelp,
        onShowContent: (e) => {
          setShowHelp(true)
        },
        onHideContent: (e, { documentClick }) => {
          setShowHelp(false)
        },
        on: 'click',
        screenReaderLabel: 'Help',
        shouldContainFocus: true,
        shouldReturnFocus: true,
        shouldCloseOnDocumentClick: true,
        placement: 'bottom end',
        children: <TeacherNavHeaderHelpPopover helpItem={helpItem} />,
      }}
      {...selectorAttrs(`top-nav-help`)}
    >
      Help
    </TopNavBar.Item>
  )
}

const TeacherNavHeaderHelpPopover = ({ helpItem }: { helpItem: HelpItem }) => {
  return (
    <View display={'block'} width={'18.5rem'} padding={'mediumSmall'}>
      <Flex direction={'column'} gap={'medium'}>
        <View>
          <Flex direction={'column'} gap={'x-small'}>
            <Heading level={'h3'}>Helpful Resources</Heading>
            <Text>
              Check out instructional articles and training videos whenever you
              need them.
            </Text>
            <Flex gap={'small'}>
              <Button
                href={helpItem.helpCenter.href}
                target={'_blank'}
                display={'block'}
              >
                {helpItem.helpCenter.label}
              </Button>
              <Button
                href={helpItem.onlinePd.href}
                target={'_blank'}
                display={'block'}
              >
                Online PD
              </Button>
            </Flex>
          </Flex>
        </View>
        <Button
          display={'block'}
          href={helpItem.supportRequest.href}
          target={'_blank'}
        >
          {helpItem.supportRequest.label}
        </Button>
        {helpItem.supportContact && (
          <Flex direction={'column'} gap={'xxx-small'}>
            <Heading level={'h4'}>Still have questions?</Heading>
            <Text>Reach out to your support contact:</Text>
            <Flex.Item>
              <Text>{helpItem.supportContact.name}</Text>
              <br />
              <Text>{helpItem.supportContact.phone}</Text>
            </Flex.Item>
            <Link
              isWithinText={false}
              href={'mailto:' + helpItem.supportContact.email}
              target={'_blank'}
            >
              Email your support contact
            </Link>
          </Flex>
        )}
        {helpItem.adminSupportContact && (
          <Flex direction={'column'} gap={'xxx-small'}>
            <Text>Contact the general Mastery Connect support team:</Text>
            <Flex.Item>
              <Text>{helpItem.adminSupportContact.phone}</Text>
            </Flex.Item>
          </Flex>
        )}
      </Flex>
    </View>
  )
}
TeacherNavHeaderHelpItem.displayName = 'TopNavBarItem'
