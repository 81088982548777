import { NavItem, OnNavigate } from './types'
import { Drilldown } from '@instructure/ui'
import { selectorAttrs } from '../../../../mc-ui/utils/selectorAttrs'
import { SUBMENU_MAX_WIDTH, SUBMENU_MIN_WIDTH } from './const'

const activeOptionStyle = {
  display: 'inline-block',
  fontWeight: '700',
  paddingBottom: '0.125rem',
  borderBottom: '0.0625rem solid currentColor',
}

const createAndSubmitForm = (action: string, method: string) => {
  // Create a form element
  const form = document.createElement('form')

  // Set the attributes
  form.method = 'post'
  form.action = action
  form.setAttribute('role', 'form')

  // Add CSRF token
  const csrfToken = window.CSRF_TOKEN
  const hiddenToken = document.createElement('input')
  hiddenToken.type = 'hidden'
  hiddenToken.name = 'authenticity_token'
  hiddenToken.value = csrfToken
  hiddenToken.dataset.qa = 'csrf-token'
  form.appendChild(hiddenToken)

  // Needed for rails to treat form submission as desired method type
  // see https://guides.rubyonrails.org/form_helpers.html#how-do-forms-with-patch-put-or-delete-methods-work-questionmark
  const railsMethod = document.createElement('input')
  railsMethod.type = 'hidden'
  railsMethod.name = '_method'
  railsMethod.value = method
  railsMethod.dataset.qa = 'rails-method'
  form.appendChild(railsMethod)

  // Append the form to the body and submit
  document.body.appendChild(form)
  form.submit()
}

function generateSubmenu(
  navItem: NavItem,
  activeIds: NavItem['id'][],
  onNavigate: OnNavigate,
  onShowPaywall: () => void,
  level = 0
) {
  const { id: pageId, label, submenu = [], group } = navItem

  const handleNavigate: OnNavigate = (id) => {
    const idArray = Array.isArray(id) ? id : [id]
    // recursively populates the 'activeIds' array with all nodes from the current tree branch.
    onNavigate(level > 0 ? [pageId, ...idArray] : [...idArray])
  }

  const buildOption = ({
    id,
    label,
    href,
    submenu,
    showPaywall,
    onClick,
    renderBeforeLabel,
    renderAfterLabel,
    customPopoverConfig,
    form,
    externalLink,
  }: NavItem) => {
    const isActive = activeIds.includes(id)
    const isCurrentPage = activeIds[activeIds.length - 1] === id
    let onOptionClick = onClick

    if (href && !submenu) {
      onOptionClick = () => {
        handleNavigate(id)
      }
    }

    if (showPaywall) {
      onOptionClick = onShowPaywall
    }

    if (form) {
      onOptionClick = () => {
        handleNavigate(id)
        createAndSubmitForm(form.action, form.method)
      }
    }

    if (externalLink && href) {
      onOptionClick = () => {
        window.open(href)
      }
    }

    const addHrefAttr =
      !submenu && !showPaywall && !customPopoverConfig && !form && !externalLink

    return (
      <Drilldown.Option
        key={`${id}_option`}
        id={`${id}_option`}
        aria-current={isCurrentPage ? 'page' : undefined}
        subPageId={submenu ? id : undefined}
        href={addHrefAttr ? href : undefined}
        onOptionClick={onOptionClick}
        afterLabelContentVAlign="center"
        renderBeforeLabel={renderBeforeLabel}
        renderAfterLabel={renderAfterLabel}
        {...selectorAttrs(`nav-drilldown-option-${id}`)}
      >
        {isActive ? <span style={activeOptionStyle}>{label}</span> : label}
      </Drilldown.Option>
    )
  }

  const buildGroup = ({ id, label, submenu }: NavItem) => {
    return (
      <Drilldown.Group
        id={`${id}_group`}
        key={`${id}_group`}
        renderGroupTitle={label}
      >
        {[...submenu.map(buildOption)]}
      </Drilldown.Group>
    )
  }

  if (level === 0) {
    return (
      <Drilldown
        rootPageId={pageId}
        maxWidth={SUBMENU_MAX_WIDTH}
        minWidth={SUBMENU_MIN_WIDTH}
        onToggle={(_event, { shown, goToPage }) => {
          // If the submenu is displayed and the current page is set as active, then navigate to the deepest-level page within that submenu.
          if (shown && activeIds[0] === pageId) {
            goToPage(activeIds[activeIds.length - 2])
          }
        }}
      >
        {generateSubmenu(
          navItem,
          activeIds,
          handleNavigate,
          onShowPaywall,
          level + 1
        )}
      </Drilldown>
    )
  } else {
    const submenuPages = submenu
      .filter((item) => item.submenu)
      .reduce(
        (pages: NavItem[], submenuItem) => [
          ...pages,
          ...generateSubmenu(
            submenuItem,
            activeIds,
            handleNavigate,
            onShowPaywall,
            level + 1
          ),
        ],
        []
      )

    return group
      ? [...submenuPages]
      : [
          <Drilldown.Page
            id={pageId}
            key={pageId}
            renderTitle={level === 1 ? null : label}
            {...selectorAttrs(`nav-drilldown-page-${pageId}`)}
          >
            {[
              ...submenu.map((item) => {
                return item.group ? buildGroup(item) : buildOption(item)
              }),
            ]}
          </Drilldown.Page>,
          ...submenuPages,
        ]
  }
}

export { generateSubmenu }
