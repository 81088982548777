import { TopNavBar } from '@instructure/ui'
import { generateSubmenu } from './generateSubmenu'
import { selectorAttrs } from '../../../../mc-ui/utils/selectorAttrs'
import { NavItem, OnNavigate } from './types'

export const UserMenuItems = ({
  userMenuItem,
  activeIds,
  onNavigate,
  onShowPaywall,
}: {
  userMenuItem: NavItem
  activeIds: NavItem['id'][]
  onNavigate: OnNavigate
  onShowPaywall: () => void
}) => {
  return (
    <TopNavBar.Item
      id={userMenuItem.id}
      renderSubmenu={
        userMenuItem.submenu &&
        generateSubmenu(userMenuItem, activeIds, onNavigate, onShowPaywall)
      }
      onSubmenuToggle={userMenuItem.onClick}
      {...selectorAttrs(`top-nav-${userMenuItem.id}`)}
    >
      {userMenuItem.label}
    </TopNavBar.Item>
  )
}
UserMenuItems.displayName = 'TopNavBarItem'
