import { enhanceNavItems } from '../../utils'
import { NavItem } from '../../BaseNavHeader/types'
import { NAV_ITEM_ICON_SIZE } from '../../BaseNavHeader/const'
import { IconExportLine, IconSettingsLine } from '@instructure/ui-icons'
import { NavItemConfig } from '../../types'
import { StudentNavItemID } from '../types'


const userEnhanceConfig = {
  user_edit: {
    renderBeforeLabel: () => <IconSettingsLine size={NAV_ITEM_ICON_SIZE} />,
  },
  user_logout: {
    renderBeforeLabel: () => <IconExportLine size={NAV_ITEM_ICON_SIZE} />,
  },
}

const useUserMenu = (
  userMenuItemConfig: NavItemConfig<StudentNavItemID>
) => {

  const userMenuItems = [
    {
      ...userMenuItemConfig,
      submenu: [
        ...userMenuItemConfig.submenu,
      ],
    },
  ]

  // Adding Icons to the user menu items
  const enhancedUserItem = enhanceNavItems(userMenuItems, userEnhanceConfig)[0]

  const userMenuItem: NavItem = { ...enhancedUserItem }
  return userMenuItem
}

export { useUserMenu }
