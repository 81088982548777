import {
  Button,
  CloseButton,
  Flex,
  Heading,
  Link,
  Modal,
  Text,
  View,
} from '@instructure/ui'
import { selectorAttrs } from 'mc-ui/utils/selectorAttrs'

export interface PaywallModalProps {
  onCancel: () => void
  open: boolean
}

export const PaywallModal = ({ open, onCancel }: PaywallModalProps) => {
  const heading = 'Upgrade to Get This Feature'
  const contactUsLink = 'https://www.instructure.com/contact-us'
  const content = (
    <Text>
      Please contact a member of our Sales team at 1-800-203-6755 or{' '}
      <Link href={contactUsLink}>instructure.com/contact-us</Link>.
    </Text>
  )

  return (
    <Modal open={open} label={heading} {...selectorAttrs('paywall_modal')}>
      <Modal.Header>
        <Flex alignItems={'center'}>
          <Heading>{heading}</Heading>
          <View margin={'0 0 0 auto'}>
            <CloseButton
              onClick={onCancel}
              screenReaderLabel={'Close'}
              {...selectorAttrs('paywall_modal_close_btn')}
            />
          </View>
        </Flex>
      </Modal.Header>
      <Modal.Body>
        <View>{content}</View>
      </Modal.Body>
      <Modal.Footer>
        <Button
          color={'secondary'}
          margin={'0 x-small'}
          onClick={onCancel}
          {...selectorAttrs('paywall_modal_cancel_btn')}
        >
          Cancel
        </Button>
        <Button
          href={contactUsLink}
          color={'primary'}
          {...selectorAttrs('paywall_modal_contact_us_btn')}
        >
          Contact Us
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
