import { AbstractResource } from '../../AbstractResource'

export type TeacherDTO = {
  id: number
  loginable: boolean
  school: {
    id: number
    name: string
  }
  parent: {
    id: number | null
    loginable: boolean
  }
}
export type CurrentTeacherDTO = {
  id: number
  allTeachers: TeacherDTO[]
  hasOrIsNetwork?: boolean
}

/**
 *
 * This class is used to make requests to the current_teacher endpoint. Mainly used to get Accounts in the User Menu
 * "Accounts" is present if the user has linked accounts - users can request to get multiple accounts linked together
 * (if a user worked at multiple schools, for example)
 *
 */
class CurrentTeacherResource extends AbstractResource {
  _camelizeKeys = true
  _endpointUrl = '/api/internal/current_teacher'
  _buildResourcePath = () => this._endpointUrl

  read: () => Promise<CurrentTeacherDTO>
}

export { CurrentTeacherResource }
