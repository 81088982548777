import { TopNavBar } from '@instructure/ui'
import { NavItem, OnNavigate } from './types'
import { generateSubmenu } from './generateSubmenu'
import { selectorAttrs } from '../../../../mc-ui/utils/selectorAttrs'

export const renderNavItems = ({
  items,
  activeIds,
  onNavigate,
  onShowPaywall,
}: {
  items: NavItem[]
  activeIds: NavItem['id'][]
  onNavigate?: OnNavigate
  onShowPaywall: () => void
}) => {
  return items.map((item) => (
    <TopNavBar.Item
      {...selectorAttrs(`top-nav-${item.id}`)}
      id={item.id}
      key={item.id}
      {...(item.submenu && {
        renderSubmenu: generateSubmenu(
          item,
          activeIds,
          onNavigate,
          onShowPaywall
        ),
        'aria-label': `Open for ${item.label} menu`,
      })}
      {...(item.href &&
      !item.submenu &&
      !item.customPopoverConfig &&
      !item.showPaywall
        ? {
            href: item.href,
            onClick: () => {
              item.onClick?.()
              onNavigate?.(item.id)
            },
          }
        : {
            href: undefined,
            onClick: undefined,
          })}
      {...(item.showPaywall && {
        onClick: onShowPaywall,
      })}
    >
      {item.label}
    </TopNavBar.Item>
  ))
}
